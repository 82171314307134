import React from 'react';
import withPermission from '../../../ACL/withPermission';
// import { navigate } from '@reach/router';
import SEO from '../../../seo';
import Layout from '../../../shared/Layout';
import contentParser from '../../../utils/contentParser';
import ApiManager from '../shared/ApiManager/ApiManager';

function ApiTemplate(props) {
	// eslint-disable-next-line react/destructuring-assignment
	const { pageContext, path } = props[0];

	const {
		title,
		description,
		keywords,
		styles,
		headerMenus,
		footerTopMenus,
		footerBottomMenus,
		sidebarItems,
		contentObject,
	} = pageContext;

	return (
		<>
			{contentObject && (
				<Layout
					isPrivate
					isLogged
					type="apiDoc"
					viewMainSection={false}
					viewSideBar
					classNameMain="bg-light  api-container" // header-padding
					header="dark"
					menu={headerMenus}
					footerTopMenu={footerTopMenus}
					footerBottomMenu={footerBottomMenus}
					apiActive
					sidebarItems={sidebarItems}
					path={path}
					currentApiId={contentObject.apiId}
				>
					<SEO
						title={title}
						description={description}
						keywords={keywords}
						pathname={path}
						cssStyle={styles && contentParser.getOnlyContent(styles)}
					/>
					<ApiManager path={path} contentObject={contentObject} />
				</Layout>
			)}
		</>
	);
}

export default withPermission(ApiTemplate);

/* eslint-disable no-use-before-define */
/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
// import ReactMarkdown from 'react-markdown';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
import EditorWidget from '../../../../shared/Editor';
import AccordionWidget from '../../../../shared/AccordionWidget';

const DefinitionTab = ({ workflow, endpoint }) => {
	const fields = (workflow && workflow.fields) || [];
	const pathParams = (endpoint && endpoint.pathParameters) || [];
	const responseSuccess =
		(workflow &&
			workflow.example &&
			workflow.example.response &&
			workflow.example.response.success) ||
		[];

	const responseErrors =
		(workflow &&
			workflow.example &&
			workflow.example.response &&
			workflow.example.response.errors) ||
		[];

	const responseFields =
		(workflow &&
			workflow.example &&
			workflow.example.response &&
			workflow.example.response.fields) ||
		[];

	const request =
		(workflow && workflow.example && workflow.example.request) || [];
	const exampleTitle = workflow.example.title
		? workflow.example.title
		: 'Example Request';
	const transform = (node, index) => {
		const item = { ...node };

		if (
			item.type === 'tag' &&
			item.name === 'a' &&
			item.attribs.href.includes('http')
		) {
			item.attribs.target = '_blank';
			return convertNodeToElement(item, index, transform);
		}
		return undefined;
	};

	const options = {
		decodeEntities: true,
		transform,
	};

	const getCurlCode = (item = null) => {
		let os = 'linux';
		if (typeof window !== 'undefined') {
			if (window.navigator.appVersion.indexOf('Win') !== -1) os = 'windows';
		}
		if (os === 'windows') {
			if (item) {
				if (item.type === 'curl') {
					const { type } = item;
					let param = ' -X';
					let url = ` ${item.method} "${item.url}"`;
					if (item.headers && item.headers.length > 0) {
						item.headers.forEach((h) => {
							if (h.type === '-d') param = ' -G -X';
							url += ` ^\n  ${h.type} "${h.key}:${h.value}"`;
						});
					}
					if (item.body && item.body.length > 0) {
						item.body.forEach((b) => {
							url += ` ^\n  ${b.type} "${b.key}:${b.value}"`;
						});
					}

					return `${type}${param}${url}`;
				}
			} else {
				let result = `curl ${endpoint.method === 'POST' ? '-G -X' : '-X'} ${
					endpoint.method
				} "${endpoint.url}"`;
				if (endpoint.headers.length > 0) {
					endpoint.headers.forEach((h) => {
						result += ` ^\n     -H "${h.key}:${h.value}"`;
					});
				}
				if (endpoint.method === 'POST') {
					result += ` ^\n     -d '<JSON REQUEST>'`;
				}

				return result;
			}
		} else if (item) {
			if (item.type === 'curl') {
				const { type } = item;
				let param = ' -X';
				let url = ` ${item.method} \\\n ${item.url}`;
				if (item.headers && item.headers.length > 0) {
					item.headers.forEach((h) => {
						if (h.type === '-d' && item.method === 'GET') param = ' -GX';
						url += ` \\\n  ${h.type} '${h.key}:${h.value}'`;
					});
				}
				if (item.body && item.body.length > 0) {
					item.body.forEach((b) => {
						url += ` \\\n  ${b.type} '${b.key}:${b.value}'`;
					});
				}

				return `${type}${param}${url}`;
			}
		} else {
			let result = `curl -${endpoint.method === 'POST' ? 'X' : 'GX'} ${
				endpoint.method
			} \\\n ${endpoint.url}`;
			if (endpoint.headers.length > 0) {
				endpoint.headers.forEach((h) => {
					result += ` \\\n  -H '${h.key}:${h.value}'`;
				});
			}
			if (endpoint.method === 'POST') {
				result += ` \\\n  -d '<JSON REQUEST>'`;
			}

			return result;
		}
		return false;
	};

	const getDescription = (item) => {
		if (typeof item.description === 'object') {
			return getAccordionWidget(item.description);
		}
		if (item.url) {
			return (
				<div className="definition-wrapper">
					<p>
						{ReactHtmlParser(
							`${item.url ? `Url: ${item.url} <br/>` : ''}<p>${
								item.description
							}</p>`,
							options
						)}
					</p>
				</div>
			);
		}
		return (
			<div className="definition-wrapper">
				<p>{ReactHtmlParser(item.description, options)}</p>
			</div>
		);
	};

	const getAccordionWidget = (item) => {
		if (item.type === 'OBJECT') {
			let content = '';
			if (item.childs && item.childs.length > 0) {
				content = item.childs.map((el) => {
					return getAccordionWidget(el);
				});
			} else {
				// console.log('getAccordionWidget -> item noCHILDS', item);
				content = getDescription(item);
			}

			return (
				<AccordionWidget
					key={item.name}
					showArrowFirst
					item={{
						id: item.name,
						title: (
							<>
								<span className="title">{item.name}</span>
								<span className="data-type">
									{`OBJECT(${item.childs.length}),`}
								</span>
								{item.required ? (
									<span className="required">
										{item.requiredNote
											? `*Required ${item.requiredNote}`
											: 'Required'}
									</span>
								) : (
									<span className="optional">
										{item.requiredNote
											? `*Optional ${item.requiredNote}`
											: 'Optional'}
									</span>
								)}
							</>
						),
						content: (
							<div className="d-flex flex-column">
								{' '}
								{item.description && getDescription(item)}
								<ol className="accordion-wrapper">{content}</ol>
							</div>
						),
						tag: 'li',
						className: 'd-flex py-3 accordion-item ',
					}}
					clickFullRow
				/>
			);
		}
		if (item.type === 'ENUM') {
			let content = '';
			if (item.childs && item.childs.length > 0) {
				content = item.childs.map((el) => {
					return getAccordionWidget(el);
				});
			} else {
				// console.log('getAccordionWidget -> item noCHILDS', item);
				content = getDescription(item);
			}

			return (
				<AccordionWidget
					key={item.name}
					showArrowFirst
					isInfo
					item={{
						id: item.name,
						title: (
							<>
								<span className="title">{item.name}</span>
								<span className="data-type">
									{`ENUM[${item.childs.length}]`}
								</span>
							</>
						),
						content: (
							<div className="d-flex flex-column">
								{item.description && getDescription(item)}
								<ol className="accordion-wrapper">{content}</ol>
							</div>
						),
						tag: 'li',
						className: 'd-flex py-3 accordion-item ',
					}}
					clickFullRow
				/>
			);
		}
		if (item.type === 'LIST') {
			let content = '';
			if (item.fields && item.fields.length > 0) {
				content = item.fields.map((el) => {
					return getAccordionWidget(el);
				});
			} else {
				// console.log('getAccordionWidget -> item noCHILDS', item);
				// content = <p>{item.description}< /p>;
				// content = <p>{item.description}</p>;
				content = null;
			}

			return (
				<AccordionWidget
					key={item.name}
					showArrowFirst
					item={{
						id: item.name,
						title: (
							<>
								<span className="title">{item.name}</span>
								<span className="data-type">LIST,</span>
								{item.required ? (
									<span className="required">
										{item.requiredNote
											? `*Required ${item.requiredNote}`
											: 'Required'}
									</span>
								) : (
									<span className="optional">
										{item.requiredNote
											? `*Optional ${item.requiredNote}`
											: 'Optional'}
									</span>
								)}
							</>
						),
						content: (
							<div className="d-flex flex-column">
								{' '}
								{item.description && getDescription(item)}
								{content && <ol className="accordion-wrapper">{content}</ol>}
							</div>
						),
						tag: 'li',
						className: 'd-flex py-3 accordion-item ',
					}}
					clickFullRow
				/>
			);
		}
		return (
			<AccordionWidget
				key={item.name}
				showArrowFirst
				item={{
					id: item.name,
					title: (
						<Row>
							<Col xs="12" className="d-flex">
								<span
									className="title"
									data-title={item.name.replace(/@/g, '.')}
								>
									{item.name.replace(/@/g, '.')}
								</span>
								{item.dataType && (
									<span className="data-type">
										{`${item.dataType}${
											item.dataType !== 'Boolean' && item.dataLength
												? `, [${item.dataLength}]`
												: ``
										},`}
									</span>
								)}
								{item.required ? (
									<span className="required">
										{item.requiredNote
											? `*Required ${item.requiredNote}`
											: 'Required'}
									</span>
								) : (
									<span className="optional">
										{item.requiredNote
											? `*Optional ${item.requiredNote}`
											: 'Optional'}
									</span>
								)}
							</Col>
						</Row>
					),
					content: getDescription(item),
					tag: 'li',
					className: 'd-flex py-3 accordion-item ',
				}}
				clickFullRow
			/>
		);
	};

	return (
		<>
			<Container className="mt-4">
				{pathParams.length > 0 && (
					<Row className="p-apidocs">
						<Col>
							<h4 className="params mb-3">PATH PARAMETER FIELDS DEFINITIONS</h4>
							<ol className="accordion-wrapper">
								{pathParams &&
									pathParams.length > 0 &&
									pathParams.map((item) => {
										return getAccordionWidget(item);
									})}
							</ol>
						</Col>
					</Row>
				)}
				{fields.length > 0 && (
					<Row className="p-apidocs">
						<Col>
							<h4 className="params mb-3 text-uppercase">
								Request Fields Definitions
							</h4>
							<ol className="accordion-wrapper">
								{fields &&
									fields.length > 0 &&
									fields.map((item) => {
										return getAccordionWidget(item);
									})}
							</ol>
						</Col>
					</Row>
				)}
				{!(endpoint.url.toLowerCase().indexOf('http') === -1) && (
					<Row className="p-apidocs">
						<Col>
							<h4 className="params text-uppercase">Request Definition</h4>

							<EditorWidget
								code={getCurlCode()}
								codeLanguage="bash"
								codeTitle="Curl"
								codeBgColor="dark"
							/>
						</Col>
					</Row>
				)}
				{request && request.length > 0 && (
					<Row className="p-apidocs">
						<Col>
							<h4 className="params text-uppercase">{exampleTitle}</h4>

							{request.map((item) => {
								return (
									<React.Fragment key={JSON.stringify(item)}>
										<EditorWidget
											code={
												item.type === 'json'
													? JSON.stringify(item.data, null, 4)
													: getCurlCode(item)
											}
											codeTitle={<p>{ReactHtmlParser(item.title, options)}</p>}
											codeBgColor="light"
										/>
									</React.Fragment>
								);
							})}
						</Col>
					</Row>
				)}
				{responseFields && responseFields.length > 0 && (
					<Row className="p-apidocs">
						<Col>
							<h4 className="params">RESPONSE & FIELDS DEFINITIONS</h4>
							<ol className="accordion-wrapper">
								{responseFields.map((item) => {
									return getAccordionWidget(item);
								})}
							</ol>
						</Col>
					</Row>
				)}
				{responseSuccess && responseSuccess.length > 0 && (
					<Row className="p-apidocs">
						<Col>
							<h4 className="params">EXAMPLE RESPONSE</h4>
							{responseSuccess.map((item) => {
								return (
									<React.Fragment key={JSON.stringify(item)}>
										<EditorWidget
											code={JSON.stringify(item.data, null, 4)}
											codeTitle={<p>{ReactHtmlParser(item.title)}</p>}
											codeBgColor="dark"
										/>
									</React.Fragment>
								);
							})}
						</Col>
					</Row>
				)}
				{responseErrors && responseErrors.length > 0 && (
					<Row className="p-apidocs">
						<Col>
							<h4 className="params">ERRORS</h4>
							{responseErrors.map((item) => {
								return (
									<React.Fragment key={JSON.stringify(item.data)}>
										<EditorWidget
											code={JSON.stringify(item.data, null, 4)}
											codeBgColor="dark"
											codeTitle={<p>{ReactHtmlParser(item.title)}</p>}
										/>
									</React.Fragment>
								);
							})}
						</Col>
					</Row>
				)}
			</Container>
		</>
	);
};

DefinitionTab.propTypes = {
	fields: PropTypes.arrayOf(PropTypes.any),
	response: PropTypes.objectOf(PropTypes.any),
	errors: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

DefinitionTab.defaultProps = {
	fields: [],
	response: {},
	errors: [],
};

export default DefinitionTab;

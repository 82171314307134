/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import EditorWidget from '../../../../shared/Editor';
import useReduxHandler from '../../../../../hooks/useReduxHandler';
import FormWidget from '../../../../shared/FormWidget/FormWidget';
import { fixUrl } from './util';

const TryItOutTab = ({ workflow, endpoint, apiId }) => {
	const fields = (workflow && workflow.fields) || [];
	const queryParams = (endpoint && endpoint.queryParameters) || [];
	const pathParams = (endpoint && endpoint.pathParameters) || [];

	const {
		getApiData,
		postApiData,
		deleteApiData,
		putApiData,
		patchApiData,
		getLastRecord,
		getRecordLoading,
	} = useReduxHandler();
	const [lastRecord, setLastRecord] = useState(getLastRecord());

	const handleSubmitEvent = (outsideData) => {
		const data = outsideData;
		const queryData = [];
		const pathData = [];
		let bodyData = {};

		Object.keys(data).forEach((item) => {
			queryParams.forEach((el) => {
				if (item === el.name && data[item] && data[item] !== '') {
					queryData.push({ key: item, value: data[item] });
				}
			});
		});
		Object.keys(data).forEach((item) => {
			pathParams.forEach((el) => {
				if (item === el.name && data[item] && data[item] !== '') {
					pathData.push({ key: item, value: data[item] });
				}
			});
		});
		Object.keys(data).forEach((item) => {
			fields.forEach((el) => {
				if (
					item === el.name &&
					typeof data[item] !== 'undefined' &&
					data[item] !== ''
				) {
					if (el.isEnum) {
						const enumValues = [];
						Object.keys(data[item]).forEach((key) => {
							if (data[item][key] === true) {
								enumValues.push(key.replace(/@/g, '.'));
							}
						});
						bodyData = { ...bodyData, [item]: enumValues };
					} else {
						bodyData = { ...bodyData, [item]: data[item] };
					}
				}
			});
		});

		const apiUrl = fixUrl(endpoint.url, queryData, pathData);
		switch (endpoint.method) {
			case 'GET':
				getApiData(apiId, apiUrl, pathData, queryData);
				break;
			case 'DELETE':
				deleteApiData(apiId, apiUrl, bodyData, pathData, queryData);
				break;
			case 'PUT':
				putApiData(apiId, apiUrl, bodyData, pathData, queryData);
				break;
			case 'PATCH':
				patchApiData(apiId, apiUrl, bodyData, pathData, queryData);
				break;
			default:
				postApiData(apiId, apiUrl, bodyData, pathData, queryData);
				break;
		}
	};

	useEffect(() => {
		setLastRecord(getLastRecord());
	}, [getRecordLoading()]);

	return (
		<>
			<Container fluid className="mt-4">
				<Row className="p-apidocs">
					<Col xs="12" md="12">
						<FormWidget
							queryParams={queryParams}
							pathParams={pathParams}
							bodyParams={fields}
							handleSubmitEvent={handleSubmitEvent}
						/>
					</Col>
					<Col md="12">
						{lastRecord && (
							<>
								<p>Last Response</p>
								<EditorWidget
									key={
										lastRecord[apiId]
											? JSON.stringify(lastRecord[apiId].response, null, 4)
											: 'No last response'
									}
									code={
										lastRecord[apiId]
											? JSON.stringify(lastRecord[apiId].response, null, 4)
											: 'No last response'
									}
									codeLanguage="json"
									codeBgColor="dark"
								/>
							</>
						)}
					</Col>
				</Row>
			</Container>
		</>
	);
};

TryItOutTab.propTypes = {
	endpoint: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default TryItOutTab;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaRegCopy, FaCheckDouble } from 'react-icons/fa';
import { fixUrl } from '../../templates/shared/ApiManager/util';

const ApiHero = ({ method, title, endpoint, description }) => {
	const [copied, setCopied] = useState(false);
	function changeCopied() {
		setCopied(true);
		setTimeout(() => setCopied(false), 5000);
	}

	function getTextByType(item) {
		switch (item.type) {
			case 'STRING':
				return (
					<React.Fragment key={item.text}>
						<ReactMarkdown source={item.text} />
					</React.Fragment>
				);
			case 'LIST':
				return (
					<ol key={JSON.stringify(item)}>
						{item.items.length > 0 &&
							item.items.map((el) => {
								return (
									<li type={el.index} key={el.index}>
										<ReactMarkdown source={el.value} />
									</li>
								);
							})}
					</ol>
				);

			default:
				return <ReactMarkdown source={item.text} />;
		}
	}

	const endpointUrl = fixUrl(
		endpoint.replace(/&lt;/g, '<').replace(/&gt;/g, '>')
	);

	return (
		<>
			<Row noGutters className="work-area-header">
				<Col md="12">
					<h1 className="work-area-header-title">{title}</h1>
				</Col>

				<Col md="12" className="mt-4 mb-3 d-flex align-items-start">
					<span className="work-area-header-badge mr-2">{method}</span>
					<div className="work-area-header-endpoint-wrapper">
						<span className="work-area-header-endpoint mr-2">
							{endpointUrl}
						</span>
					</div>
					<CopyToClipboard text={endpointUrl} onCopy={changeCopied}>
						<button
							type="button"
							className="btn-copy"
							title="Copy to Clipboard"
						>
							{copied ? (
								<FaCheckDouble color="green" />
							) : (
								<FaRegCopy color="#4E4E4E" />
							)}
						</button>
					</CopyToClipboard>
				</Col>
				<Col md="12" className="work-area-header-description">
					{description &&
						description.length > 0 &&
						description.map((item) => {
							return getTextByType(item);
						})}
				</Col>
			</Row>
		</>
	);
};

ApiHero.propTypes = {
	method: PropTypes.string,
	title: PropTypes.string,
	endpoint: PropTypes.string,
};

ApiHero.defaultProps = {
	method: 'GET',
	title: 'Create ...',
	endpoint: 'https://sandbox.billshark.com',
};

export default ApiHero;

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import useReduxHandler from '../../hooks/useReduxHandler';
import ROUTES from '../utils/routes';
import { getIsShowLink } from '../utils/linkUtils';

const AuthProvider = ({ children, path }) => {
	const { isLoggedIn, isBrowser, getUser } = useReduxHandler();

	if (isLoggedIn()) {
		if (!getIsShowLink(getUser()?.resources_fe, path)) {
			navigate(ROUTES.NOT_FOUND);
			return null;
		}
		return children;
	}
	if (isBrowser()) navigate(ROUTES.LOGIN);
	return null;
};
AuthProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};
AuthProvider.defaultProps = {
	children: null,
};
export default (WrappedComponent) => {
	const hocComponent = (...props) => {
		const { path } = props[0];
		return (
			<AuthProvider path={path}>
				<WrappedComponent {...props} />
			</AuthProvider>
		);
	};
	return hocComponent;
};

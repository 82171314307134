/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react';
import Select from 'react-select';
import { Container, Row, Col, FormGroup, Label } from 'reactstrap';
import SectionContainer from '../../../sections/SectionContainer';
import ApiHero from '../../../sections/ApiHero';
import TabWidget from '../../../../shared/Tab';
// import contentParser from '../../../../utils/contentParser';
import DefinitionTab from './DefinitionTab';
import TryItOutTab from './TryItOutTab';

const ApiManager = ({ path, contentObject }) => {
	const {
		workflows,
		endpoint,
		description,
		disabledWorkflowsOnTryItOut = null,
		version,
		title,
		apiId,
	} = contentObject;

	const [currentWorkFlow, setCurrentWorkFlow] = useState(
		workflows?.length > 0 ? workflows[0] : []
	);

	const [showWorkFlow, setShowWorkFlow] = useState(true);

	function changeCurrentWorkFlow(value) {
		let selected = null;
		workflows.forEach((element) => {
			if (element.id === Number(value)) {
				selected = element;
			}
		});

		setCurrentWorkFlow(selected); // TODO mejorar esto
	}

	const getTabs = () => {
		if (currentWorkFlow && currentWorkFlow.hideTryOutTab) {
			return [
				{
					id: '1',
					label: 'Definition',
					content: (
						<DefinitionTab workflow={currentWorkFlow} endpoint={endpoint} />
					),
				},
			];
		}
		return [
			{
				id: '1',
				label: 'Definition',
				content: (
					<DefinitionTab workflow={currentWorkFlow} endpoint={endpoint} />
				),
			},
			{
				id: '2',
				label: 'Try It Out',
				content: (
					<TryItOutTab
						workflow={currentWorkFlow}
						endpoint={endpoint}
						apiId={apiId}
					/>
				),
			},
		];
	};

	const getWorkflowOptions = () => {
		const r = workflows.map((item) => {
			return { value: item.id, label: item.title };
		});
		return r;
	};

	const handlerTabChange = (tab) => {
		if (tab === '2' && disabledWorkflowsOnTryItOut) {
			setShowWorkFlow(false);
		} else {
			setShowWorkFlow(true);
		}
	};

	return (
		<>
			<SectionContainer jsonversion={version}>
				<Container fluid className="px-0">
					<ApiHero
						method={endpoint.method}
						title={title}
						endpoint={endpoint.url}
						description={description}
						path={path}
					/>
					<Row className="flex-column-reverse flex-md-row workArea">
						<Col md="12">
							{workflows && workflows.length > 1 && (
								<FormGroup className="work-area-header workflows">
									<Label>Select Product (Sub-product)</Label>
									<Select
										key={`${title}-workflows`}
										isSearchable
										placeholder="Seleccionar..."
										options={getWorkflowOptions()}
										defaultValue={getWorkflowOptions()[0]}
										onChange={(e) => changeCurrentWorkFlow(e.value)}
										// menuPortalTarget={document.querySelector('.workArea')}
										// menuPosition="fixed"
										portalPlacement="bottom"
										className="mb-4 w-100"
										isDisabled={!showWorkFlow}
									/>
								</FormGroup>
							)}
							<TabWidget
								tabs={getTabs()}
								onTabChange={handlerTabChange}
								key={`${title}-tabs`}
							/>
						</Col>
					</Row>
				</Container>
			</SectionContainer>
		</>
	);
};

export default ApiManager;

/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { useForm } from 'react-hook-form';
import RandExp from 'randexp';
import { FaPenAlt } from 'react-icons/fa';
import { AiFillCloseCircle } from 'react-icons/ai';
import FormField from '../FormField';
import useReduxHandler from '../../../hooks/useReduxHandler';
import {
	getNumberValuesAndRemoveKeys,
	getObjectResult,
	getArrayResult,
} from './util';

const FormWidget = ({
	queryParams,
	pathParams,
	bodyParams,
	handleSubmitEvent,
}) => {
	const { getRecordToLoad, isRecordLoading } = useReduxHandler();
	const recordToLoad = getRecordToLoad();

	const getPathParams = () => {
		let obj = {};
		if (recordToLoad && recordToLoad.pathData.length > 0) {
			recordToLoad.pathData.forEach((el) => {
				obj = { ...obj, [el.key]: el.value };
			});
		}
		return obj;
	};
	const getQueryParams = () => {
		let obj = {};
		if (recordToLoad && recordToLoad.queryData.length > 0) {
			recordToLoad.queryData.forEach((el) => {
				obj = { ...obj, [el.key]: el.value };
			});
		}
		return obj;
	};

	const getBodyParams = () => {
		let obj = {};
		if (
			recordToLoad &&
			recordToLoad.request &&
			recordToLoad.request.body &&
			recordToLoad.request.body.length > 0
		) {
			const bodyData = JSON.parse(recordToLoad.request.body);
			Object.keys(bodyData).forEach((el) => {
				if (Array.isArray(bodyData[el])) {
					bodyData[el].forEach((item, i) => {
						Object.keys(item).forEach((tmp) => {
							obj = { ...obj, [`${el}[${i}].${tmp}`]: item[tmp] };
						});
					});
				} else if (typeof bodyData[el] === 'object') {
					Object.keys(bodyData[el]).forEach((item) => {
						obj = { ...obj, [`${el}.${item}`]: bodyData[el][item] };
					});
				} else {
					obj = { ...obj, [el]: bodyData[el] };
				}
			});
		}
		return obj;
	};

	const getDefaultValues = () => {
		const pathParamsData = getPathParams();
		const queryParamsData = getQueryParams();
		const bodyParamsData = getBodyParams();
		const defaultValues = {
			...queryParamsData,
			...pathParamsData,
			...bodyParamsData,
		};
		return defaultValues;
	};

	const {
		register,
		errors,
		handleSubmit,
		watch,
		setValue,
		getValues,
		control,
		reset,
	} = useForm();

	const isJsonString = (str) => {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	};

	const fieldsIntoObject = (fields = []) => {
		const result = {};
		fields.forEach((el) => {
			result[el.key] = el.value;
		});

		return result;
	};
	const filterResult = (values) => {
		let data = { ...values };

		Object.keys(data).forEach((el) => {
			if (el === 'metadata' && data[el].length > 0) {
				data = { ...data, metadata: { ...fieldsIntoObject(data?.metadata) } };
			} else if (typeof data[el] === 'object' && !Array.isArray(data[el])) {
				data = { ...data, [el]: { ...filterResult(data[el]) } };
			} else if (typeof data[el] === 'object' && Array.isArray(data[el])) {
				data = { ...data, [el]: data[el] };
			}
		});

		return data;
	};
	const onSubmit = (values, e) => {
		const tValues = {...values};
		if( tValues?.service_request_type !== 'cancellation') {
			delete tValues.sub_type;
		}
		const data = filterResult(tValues);

		const { numberValues, removeKeys } = getNumberValuesAndRemoveKeys(e);

		let resultObject = {};
		Object.keys(data).forEach((el) => {
			//          if (!data[el]) return;
			if (typeof data[el] === 'object' && !Array.isArray(data[el])) {
				if (data[el] && data[el].value) {
					// is a selecttype
					resultObject = {
						...resultObject,
						[el.replace('@', '.')]: data[el].value,
					};
				} else {
					const insideData = data[el];
					resultObject = {
						...resultObject,
						...getObjectResult(el, insideData, removeKeys, numberValues),
					};
				}
			} else if (typeof data[el] === 'object' && Array.isArray(data[el])) {
				const insideData = data[el];
				const tempArray = [];
				insideData.forEach((insideEl, i) => {
					tempArray.push(
						getArrayResult(el, insideEl, i, removeKeys, numberValues)
					);
				});
				resultObject = { ...resultObject, [el]: tempArray };
			} else {
				const keyToRemove = Object.keys(removeKeys).some((key) => key === el);
				const customValue = Object.keys(numberValues).find((key) => key === el);
				if (!keyToRemove) {
					if (customValue) {
						resultObject = {
							...resultObject,
							[el.replace('@', '.')]: numberValues[customValue],
						};
					} else if (
						isJsonString(data[el]) &&
						Array.isArray(JSON.parse(data[el]))
					) {
						const temp = JSON.parse(data[el]);
						if (Array.isArray(temp) && temp.length > 0) {
							resultObject = {
								...resultObject,
								[el.replace('@', '.')]: temp,
							};
						}
					} else {
						resultObject = {
							...resultObject,
							[el.replace('@', '.')]: data[el],
						};
					}
				}
			}
		});
		handleSubmitEvent(resultObject);
	};

	const isResetFields = (where) => {
		let params = [];
		switch (where) {
			case 'path':
				params = pathParams;
				break;
			case 'query':
				params = queryParams;
				break;
			default:
				params = bodyParams;
				break;
		}
		let result = false;
		params.forEach((item) => {
			if (item.type !== 'LIST' && watch(item.name)) {
				result = true;
			}
		});
		return result;
	};

	const checkForSample = (params) => {
		if (params && params.length > 0) {
			for (const item of params) {
				if (
					item.type !== 'OBJECT' &&
					item.type !== 'LIST' &&
					item.sample &&
					item.sample.regexp
				) {
					return true;
				}
				if (item.type === 'OBJECT') {
					return checkForSample(item.childs);
				}
				if (item.type === 'LIST') {
					return checkForSample(item.fields);
				}
			}
		}
		return false;
	};

	const isfillFields = (where) => {
		let params = [];
		switch (where) {
			case 'path':
				params = pathParams;
				break;
			case 'query':
				params = queryParams;
				break;
			default:
				params = bodyParams;
				break;
		}
		return checkForSample(params);
	};

	const resetFields = (where) => {
		let params = [];
		switch (where) {
			case 'path':
				params = pathParams;
				break;
			case 'query':
				params = queryParams;
				break;
			default:
				params = bodyParams;
				break;
		}
		params.forEach((item) => {
			if (item.type === 'SELECT') {
				console.log('select ', item);
			}
			if (item.type !== 'LIST') {
				setValue(item.name, '');
			}
		});
		reset();
	};

	const fillFieldData = (item, dataValues, name = '') => {
		if (
			item.type === 'INPUT' &&
			item.sample &&
			item.sample.regexp &&
			!dataValues[`${name}${item.name}`]
		) {
			setValue(`${name}${item.name}`, new RandExp(item.sample.regexp).gen(), {
				shouldValidate: true,
				shouldDirty: true,
			});
		} else if (item.type === 'OBJECT' && item.childs.length > 0) {
			item.childs.forEach((el) => {
				fillFieldData(el, dataValues, `${name}${item.name}.`);
			});
		}
	};

	const fillFields = (where) => {
		let params = [];
		switch (where) {
			case 'path':
				params = pathParams;
				break;
			case 'query':
				params = queryParams;
				break;
			default:
				params = bodyParams;
				break;
		}
		const dataValues = getValues();
		params.forEach((item) => {
			fillFieldData(item, dataValues);
		});
	};

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)} noValidate>
				{queryParams && queryParams.length > 0 && (
					<>
						<p className="params text-uppercase">Query Params</p>
						<Row className="mb-3">
							<Col className="d-flex">
								{isfillFields('query') && (
									<button
										type="button"
										className="btn btn-default mr-2"
										onClick={() => fillFields('query')}
									>
										<FaPenAlt />
										Fill out empty fields
									</button>
								)}

								{isResetFields('query') && (
									<button
										type="button"
										className="btn btn-default"
										onClick={() => resetFields('query')}
									>
										<AiFillCloseCircle className="mr-2" />
										Clear all fields
									</button>
								)}
							</Col>
						</Row>
						<Row>
							{queryParams.map((item) => {
								return (
									<Col
										md="6"
										key={JSON.stringify(item)}
										className={`mb-2 ${
											item.type === 'HIDDEN' ? 'd-none' : ''
										} ${
											item.name === 'sub_type' &&
											watch('service_request_type') !== 'cancellation'
												? 'd-none'
												: ''
										}`}
									>
										<FormField
											item={item}
											register={register}
											errors={errors}
											watch={watch}
											setValue={setValue}
											defaultValues={
												item.defaultValue
													? { [item.name]: item.defaultValue }
													: getDefaultValues()
											}
											control={control}
											valueInput={getValues(item.name)}
										/>
									</Col>
								);
							})}
						</Row>
					</>
				)}
				{pathParams && pathParams.length > 0 && (
					<>
						<p className="params">Path Params:</p>
						<Row className="mb-3">
							<Col className="d-flex">
								{isfillFields('path') && (
									<button
										type="button"
										className="btn btn-default mr-2"
										onClick={() => fillFields('path')}
									>
										<FaPenAlt />
										Fill out empty fields
									</button>
								)}

								{isResetFields('path') && (
									<button
										type="button"
										className="btn btn-default"
										onClick={() => resetFields('path')}
									>
										<AiFillCloseCircle className="mr-2" />
										Clear all fields
									</button>
								)}
							</Col>
						</Row>
						<Row>
							{pathParams.map((item) => {
								return (
									<Col
										md="6"
										key={JSON.stringify(item)}
										className={`mb-2 ${item.type === 'HIDDEN' ? 'd-none' : ''}`}
									>
										<FormField
											item={item}
											register={register}
											errors={errors}
											watch={watch}
											setValue={setValue}
											defaultValues={
												item.defaultValue
													? { [item.name]: item.defaultValue }
													: getDefaultValues()
											}
											control={control}
											valueInput={getValues(item.name)}
										/>
									</Col>
								);
							})}
						</Row>
					</>
				)}
				{bodyParams && bodyParams.length > 0 && (
					<>
						<p className="params">Body Params</p>
						<Row className="mb-3">
							<Col className="d-flex">
								{isfillFields('body') && (
									<button
										type="button"
										className="btn btn-default mr-2"
										onClick={() => fillFields('body')}
									>
										<FaPenAlt className="mr-2" />
										Fill out empty fields
									</button>
								)}

								{isResetFields('body') && (
									<button
										type="button"
										className="btn btn-default"
										onClick={() => resetFields('body')}
									>
										<AiFillCloseCircle className="mr-2" />
										Clear all fields
									</button>
								)}
							</Col>
						</Row>
						<Row>
							{bodyParams.map((item) => {
								return (
									item.type !== 'LIST' &&
									item.type !== 'OBJECT' && (
										<Col
											md="6"
											key={JSON.stringify(item)}
											className={`mb-2 ${
												item.type === 'HIDDEN' ? 'd-none' : ''
											}`}
										>
											<FormField
												item={item}
												register={register}
												errors={errors}
												watch={watch}
												setValue={setValue}
												defaultValues={
													typeof item.defaultValue !== 'undefined'
														? { [item.name]: item.defaultValue }
														: getDefaultValues()
												}
												control={control}
												valueInput={getValues(item.name)}
											/>
										</Col>
									)
								);
							})}
						</Row>
						<Row>
							{bodyParams.map((item) => {
								return (
									item.type === 'LIST' && (
										<Col
											md="12"
											key={item.name}
											className={`${item.type === 'HIDDEN' ? 'd-none' : ''}`}
										>
											<FormField
												item={item}
												register={register}
												errors={errors}
												watch={watch}
												setValue={setValue}
												defaultValues={
													typeof item.defaultValue !== 'undefined'
														? { [item.name]: item.defaultValue }
														: getDefaultValues()
												}
												control={control}
												valueInput={getValues(item.name)}
											/>
										</Col>
									)
								);
							})}
						</Row>
						<Row>
							{bodyParams.map((item) => {
								return (
									item.type === 'OBJECT' && (
										<Col
											md="12"
											key={item.name}
											className={`${
												item.type === 'HIDDEN' ? 'd-none' : ''
											} d-flex align-items-end justify-content-center`}
										>
											<FormField
												item={item}
												register={register}
												errors={errors}
												watch={watch}
												setValue={setValue}
												defaultValues={
													typeof item.defaultValue !== 'undefined'
														? { [item.name]: item.defaultValue }
														: getDefaultValues()
												}
												control={control}
												valueInput={getValues(item.name)}
											/>
										</Col>
									)
								);
							})}
						</Row>
					</>
				)}
				<Row>
					<Col className="d-flex align-items-end justify-content-center">
						<button
							type="submit"
							className="btn btn-primary btn-send-response my-4"
							disabled={isRecordLoading()}
						>
							Send Request
						</button>
					</Col>
				</Row>
			</form>
		</>
	);
};

FormWidget.propTypes = {
	pathParams: PropTypes.arrayOf(PropTypes.any).isRequired,
	bodyParams: PropTypes.arrayOf(PropTypes.any).isRequired,
	handleSubmitEvent: PropTypes.func.isRequired,
};

export default FormWidget;

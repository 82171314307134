export const getNumberValuesAndRemoveKeys = (e) => {
	let numberValues = {};
	let removeKeys = {};
	Object.keys(e.target).forEach((el) => {
		if (e.target[el].required === false) {
			if (e.target[el].value === '' && e.target[el].type !== 'checkbox') {
				removeKeys = { ...removeKeys, [e.target[el].name]: true };
			}
		}
		if (e.target[el].value && e.target[el].type === 'number') {
			numberValues = {
				...numberValues,
				[e.target[el].name]: parseInt(e.target[el].value, 10),
			};
		}
	});
	return { numberValues, removeKeys };
};

export const getObjectResult = (el, insideData, removeKeys, numberValues) => {
	let resultObject = {};
	Object.keys(insideData).forEach((insideKey) => {
		if (
			typeof insideData[insideKey] === 'object' &&
			!Array.isArray(insideData[insideKey]) &&
			insideData[insideKey]
		) {
			if (insideData[insideKey].value) {
				resultObject = {
					...resultObject,
					[el.replace('@', '.')]: {
						...resultObject[el.replace('@', '.')],
						[insideKey.replace('@', '.')]: insideData[insideKey].value,
					},
				};
			} else {
				const tObject = insideData[insideKey];

				Object.keys(tObject).forEach((other) => {
					const keyToRemove = Object.keys(removeKeys).some(
						(key) => key === `${el}.${insideKey}.${other}`
					);

					const customValue = Object.keys(numberValues).find(
						(key) => key === `${el}.${insideKey}.${other}`
					);
					if (!keyToRemove) {
						resultObject = {
							...resultObject,
							[el.replace('@', '.')]: {
								...resultObject[el.replace('@', '.')],
								[insideKey.replace('@', '.')]: {
									...resultObject[el.replace('@', '.')][
										insideKey.replace('@', '.')
									],
									[other.replace('@', '.')]: customValue
										? numberValues[customValue]
										: tObject[other],
								},
							},
						};
					}
				});
			}
		} else if (
			typeof insideData[insideKey] === 'object' &&
			Array.isArray(insideData[insideKey]) &&
			insideData[insideKey]
		) {
			resultObject = {
				...resultObject,
				[el.replace('@', '.')]: {
					...resultObject[el.replace('@', '.')],
					[insideKey.replace('@', '.')]: insideData[insideKey],
				},
			};
		} else {
			const keyToRemove = Object.keys(removeKeys).some(
				(key) => key === `${el}.${insideKey}`
			);

			const customValue = Object.keys(numberValues).find(
				(key) => key === `${el}.${insideKey}`
			);
			if (!keyToRemove) {
				resultObject = {
					...resultObject,
					[el.replace('@', '.')]: {
						...resultObject[el.replace('@', '.')],
						[insideKey.replace('@', '.')]: customValue
							? numberValues[customValue]
							: insideData[insideKey],
					},
				};
			}
		}
	});
	return resultObject;
};

export const getArrayResult = (
	el,
	insideData,
	index,
	removeKeys,
	numberValues
) => {
	let resultObject = {};
	Object.keys(insideData).forEach((insideKey) => {
		if (
			typeof insideData[insideKey] === 'object' &&
			insideData[insideKey] &&
			insideData[insideKey].value
		) {
			resultObject = {
				...resultObject,
				[el.replace('@', '.')]: {
					...resultObject[el.replace('@', '.')],
					[insideKey.replace('@', '.')]: insideData[insideKey].value,
				},
			};
		} else {
			const keyToRemove = Object.keys(removeKeys).some((key) => {
				return key === `${el}[${index}].${insideKey}`;
			});

			const customValue = Object.keys(numberValues).find(
				(key) => key === `${el}[${index}]${insideKey}`
			);
			if (!keyToRemove) {
				resultObject = {
					...resultObject,
					[insideKey.replace('@', '.')]: customValue
						? numberValues[customValue]
						: insideData[insideKey],
				};
			}
		}
	});
	return resultObject;
};

export default { getNumberValuesAndRemoveKeys };
